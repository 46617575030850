import styled from 'styled-components'

import carouselArrow from '../../../images/next-arrow.svg'

export const StoryCarouselSection = styled.section`
  padding: 5rem 0;
  margin: 4rem 0 0 0;

  .slick-slide {
    margin: 0 5rem;

    & > div > div {
      &:focus {
        outline: none;
      }
    }
  }

  .slick-list {
    height: 400px;
    position: relative;

    &::after {
      content: '';
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      z-index: 1;
      background: linear-gradient(270deg, #ffffff 9%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }
  }

  .slick-dots {
    display: block;
    position: absolute;
    list-style: none;
    top: -111px;
    left: 0;
    padding: 0;
    margin: 0;
    width: 50%;
    height: 50px;
    text-align: left;
    pointer-events: none;

    li {
      width: 5px;
      height: 50px;
      margin: 0 0.8rem;
    }
  }

  .slick-dots li.slick-active span {
    background-color: ${({ theme }) => theme.colors.vividBlue} !important;
    opacity: 1 !important;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  }

  @media only screen and (max-width: 590px) {
    .slick-slide {
      /* width: 299px !important; */
      margin: 0;
    }

    .slick-list {
      &::after {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    margin: 0;
    padding: 5rem 0 2rem 0;
  }
`
export const CustomSlickDot = styled.span`
  display: block;
  width: 5px;
  height: 30px;
  background-color: #c5c5c5;
  border-radius: 50px;
  transition: all ease-in-out 0.2s;
`
export const NextArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 0;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  transform: rotate(180deg);
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media only screen and (max-width: 425px) {
    right: 2px;
    top: -7px;
    opacity: 0.1;
    z-index: 1;
  }
`

export const PrevArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 70px;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media only screen and (max-width: 425px) {
    left: 2px;
    top: -7px;
    opacity: 0.1;
    z-index: 1;
  }
`

export const SlideStatus = styled.div`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 1.7rem;
  display: inline-flex;
  margin-bottom: 6rem;
  margin-left: ${({ marginLeft }) => `calc(${marginLeft} * 2.5rem)`};

  & span {
    font-size: 1.14rem;
    text-transform: uppercase;
  }

  & span:nth-of-type(1) {
    &::after {
      content: ' ';
      display: inline-flex;
      vertical-align: middle;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.veryLightGray};
      width: 100px;
      margin: 0 1rem;
    }
  }

  @media only screen and (max-width: 767px) {
    transform: translateX(15px);
    span:nth-of-type(1) {
      &::after {
        width: 50px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    display: none;
  }
`

export const CarouselCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 545px;
  height: 340px;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in-out;

  @media only screen and (max-width: 590px) {
    width: 310px;
    height: auto;
    text-align: center;
  }

  @media screen and (min-width: 414px) {
    margin: 0 2rem;
  }
`

export const CarouselCardDate = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 2.14rem;
  margin-bottom: 1rem;
`
export const CarouselCardTitle = styled.h5`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: bold;
  line-height: 2.85rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.3rem;
  }

  @media only screen and (max-width: 425px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

export const CarouselCardText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 1.71rem;
  margin-bottom: auto;
`
