import React, { useState, useCallback } from 'react'
import StorySlickCarousel from 'react-slick'
import { formatDate, truncateString } from '../../../helper'

import {
  StoryCarouselSection,
  NextArrow,
  PrevArrow,
  SlideStatus,
  CarouselCard,
  CarouselCardTitle,
  CarouselCardDate,
  CarouselCardText,
  CustomSlickDot
} from './styles'

import Container from '../../UI/Container'
import { Link } from 'gatsby'

const StoryCarousel = ({ data, locale }) => {
  const { story_timeline: stories } = data

  const [state, setState] = useState({
    activeSlide: 0
  })

  const CustomNextArrow = useCallback((props) => {
    const { onClick } = props
    return <NextArrow onClick={onClick} />
  }, [])

  const CustomPrevArrow = useCallback((props) => {
    const { onClick } = props
    return <PrevArrow onClick={onClick} />
  }, [])

  const settings = {
    customPaging: () => {
      return <CustomSlickDot />
    },
    variableWidth: true,
    className: 'slider variable-width',
    dots: true,
    arrows: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,

    onInit: () => {
      setState({ activeSlide: 1 })
    },
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    afterChange: (currentSlide) => {
      setState({ activeSlide: currentSlide + 1 })
    },
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  }

  return (
    <StoryCarouselSection>
      <Container>
        <SlideStatus marginLeft={stories.length}>
          <span>{state.activeSlide >= 10 ? `${state.activeSlide}` : `0${state.activeSlide}`}</span>
          <span>{stories.length >= 10 ? `${stories.length}` : `0${stories.length}`}</span>
        </SlideStatus>
        <StorySlickCarousel {...settings}>
          {stories.map((story, index) => {
            return (
              <div style={{ width: 450 }} key={`${index}-${story.date}`}>
                <CarouselCard>
                  <CarouselCardDate>{story.date}</CarouselCardDate>
                  <CarouselCardTitle dangerouslySetInnerHTML={{ __html: story.title }} />
                  <CarouselCardText dangerouslySetInnerHTML={{ __html: story.content }} />
                </CarouselCard>
              </div>
            )
          })}
        </StorySlickCarousel>
      </Container>
    </StoryCarouselSection>
  )
}

export default StoryCarousel
