import styled from 'styled-components'

export const StorySliderSection = styled.section`
  padding: 5rem 0 0 0;
  background: linear-gradient(to bottom, #ffffff 67%, #0d3151 50%);

  .slick-slide {
    margin: 0 4rem;
  }

  @media only screen and (max-width: 768px) {
    .slick-slide {
      margin: 0 1rem;
    }
  }

  @media only screen and (max-width: 425px) {
    .slick-list {
      height: 200px;
    }
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 8.57rem;
  font-weight: 900;
  line-height: 8.57rem;
  margin-bottom: 5rem;

  @media only screen and (max-width: 1024px) {
    font-size: 3.28rem;
    line-height: 3.57rem;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
`
export const SlideStatus = styled.div`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.lightestGrey};
  line-height: 1.7rem;
  display: inline-flex;

  & span {
    font-size: 1.14rem;
    text-transform: uppercase;
  }

  & span:nth-of-type(1) {
    &::after {
      content: '/';
      display: inline-flex;
      vertical-align: middle;
      margin: 0 1rem;
    }
  }
`

export const SliderWrapper = styled.div`
  display: flex;
  padding: 3.5rem 0 2rem 0;
  overflow: hidden;
  outline: none;
`

export const ImageWrapper = styled.figure`
  max-width: 600px;
  /* max-height: 500px; */
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 300px;
    height: auto;
  }

  @media only screen and (max-width: 425px) {
    width: 264px;
    height: auto;
  }
`
