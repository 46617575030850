import React from 'react'
import Img from 'gatsby-image'
import { StoryContentSection, StoryContentWrapper, Column, Divider, ImageContainer, Text } from './styles'

import Container from '../../UI/Container'

const StoryContent = ({ data }) => {
  const { description_left, description_right, image_left, image_right } = data
  return (
    <StoryContentSection>
      <Container>
        <StoryContentWrapper>
          <Column align="left">
            <ImageContainer>
              <Img fluid={image_left.localFile.childImageSharp.fluid} />
              <figcaption>Foto: Jure Mišković, Cropix</figcaption>
            </ImageContainer>
            <Text dangerouslySetInnerHTML={{ __html: description_left }} />
          </Column>
          <Divider />
          <Column align="right">
            <Text dangerouslySetInnerHTML={{ __html: description_right }} />
            <ImageContainer>
              <Img fluid={image_right.localFile.childImageSharp.fluid} />
              <figcaption>Foto: Jure Mišković, Cropix</figcaption>
            </ImageContainer>
          </Column>
        </StoryContentWrapper>
      </Container>
    </StoryContentSection>
  )
}

export default StoryContent
