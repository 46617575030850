import React, { useState } from 'react'
import StorySlickSlider from 'react-slick'
import { LocaleConsumer } from '../../Layout'

import { StorySliderSection, ImageWrapper, Title, SlideStatus } from './styles'
import Container from '../../UI/Container'

import OurStoryImgOne from 'assets/images/our_story_slide_1.jpg'
import OurStoryImgTwo from 'assets/images/our_story_slide_2.jpg'
import OurStoryImgThree from 'assets/images/our_story_slide_3.jpg'
import OurStoryImgFour from 'assets/images/our_story_slide_4.jpg'

const StorySlider = () => {
  const [state, setState] = useState({
    activeSlide: 0
  })

  const settings = {
    variableWidth: true,
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    // focusOnSelect: true,

    onInit: () => {
      setState({ activeSlide: 1 })
    },
    afterChange: currentSlide => {
      setState({ activeSlide: currentSlide + 1 })
    }
  }

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <StorySliderSection>
          <Container>
            <div>
              <Title>{i18n.storyQuote}</Title>
            </div>
            <StorySlickSlider {...settings}>
              <div>
                <ImageWrapper>
                  <img src={OurStoryImgOne} alt="" />
                </ImageWrapper>
              </div>
              <div>
                <ImageWrapper>
                  <img src={OurStoryImgTwo} alt="" />
                </ImageWrapper>
              </div>

              <div>
                <ImageWrapper>
                  <img src={OurStoryImgThree} alt="" />
                </ImageWrapper>
              </div>

              <div>
                <ImageWrapper>
                  <img src={OurStoryImgFour} alt="" />
                </ImageWrapper>
              </div>
            </StorySlickSlider>
            <SlideStatus>
              <span>{`0${state.activeSlide}`}</span>
              <span>04</span>
            </SlideStatus>
          </Container>
        </StorySliderSection>
      )}
    </LocaleConsumer>
  )
}

export default StorySlider
