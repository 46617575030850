import styled from 'styled-components'

export const StoryIntroWrapper = styled.div`
  padding: 5rem 0;

  @media only screen and (max-width: 768px) {
    padding: 5rem 0 1rem 0;
  }
`
export const IntroHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding: 5rem 0;

  @media only screen and (max-width: 475px) {
    height: 100%;
    padding: 6rem 0 2rem 0;
  }
`
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 8.57rem;
  font-weight: 900;
  line-height: 8.57rem;
  margin-bottom: 3rem;

  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 6.14rem;
    line-height: 6.2rem;
  }

  @media only screen and (max-width: 475px) {
    font-size: 4rem;
    line-height: 4.2rem;
    margin-bottom: 2rem;
  }
`
export const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 2.14rem;
  line-height: 2.14rem;
  opacity: 0.4;

  @media only screen and (max-width: 475px) {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
`
