import styled from 'styled-components'

export const StoryContentSection = styled.section`
  padding: 5rem 0;

  @media only screen and (max-width: 768px) {
    padding: 1rem 0;
  }
`

export const StoryContentWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`

export const Column = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-end')};

  @media only screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
  }
`

export const Divider = styled.div`
  position: absolute;
  width: 1px;
  height: 1200px;
  background: linear-gradient(0deg, rgba(197, 197, 197, 0) 0%, #eaeaea 45.16%, #eaeaea 76.04%, #1d9ed9 100%);
  transform: translateY(-15%);
  left: 50%;

  @media only screen and (max-width: 1024px) {
    display: none;
    order: 1;
  }
`
export const ImageContainer = styled.figure`
  width: 100%;
  max-width: 480px;
  height: auto;
  position: relative;

  figcaption {
    position: absolute;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.darkGrey};
    bottom: -27px;
    right: 0;
  }

  .gatsby-image-wrapper {
    border-radius: 10px;
  }

  @media only screen and (max-width: 1024px) {
    order: 1;
    height: auto;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin: 9rem 0;
  width: 480px;

  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin: 3rem 0;
  }
`
