import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import JobPositions from 'components/Job-positions'
import StoryIntro from 'components/Our-story-screen/Story-intro'
import StoryContent from 'components/Our-story-screen/Story-content'
import StoryCarousel from 'components/Our-story-screen/Story-carousel'
import StorySlider from 'components/Our-story-screen/Story-slider'

const MainContent = styled.main`
  background-color: #ffffff;
`

const OurStory = ({ data, pageContext, location }) => {
  const {
    job_positions: { edges: position },
    story: {
      intro,
      content,
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc }
    }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} />
      <StoryIntro data={intro} />
      <MainContent>
        <StoryContent data={content} />
        <StoryCarousel data={content} locale={pageContext.locale} />
        <StorySlider />
        <JobPositions data={position} locale={pageContext.locale} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default OurStory

export const query = graphql`
  fragment StoryImg on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(quality: 100, maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }

  query($id: Int, $locale: String!) {
    story: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      intro: acf {
        title
        subtitle
      }
      content: acf {
        description_left
        image_left {
          ...StoryImg
        }
        description_right
        image_right {
          ...StoryImg
        }
        story_timeline {
          date
          title
          content
        }
      }
    }

    job_positions: allWordpressWpJobs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          acf {
            title
            text
            link_text
            url
            open_positions {
              job_position
              work_location
              slug
            }
          }
        }
      }
    }
  }
`
